<template>
  <!-- header -->
  <article>
    <!-- hero -->
    <section id="customer" class="hero">
        <div class="container">
            <div class="hero-body">
                <div class="columns">
                    <div id="hero-left" class="column">
                        <p id="coming-soon">
                            Coming soon
                        </p>
                        <p id="about">
                            A marketplace that supports America and her businesses
                        </p>
                        <div id="interest-signup-container">
                            <p>Be the first to know when AXS AMERICA launches</p>
                            <div id="customer-input-wrapper">
                              <input class="input is-large" type="text" v-model="customerEmail" @focus="customerError = false"  @blur="customerError = false" placeholder="jsmith@example.com">
                              <button v-on:click="submitCustomer" class="button">NOTIFY ME</button>
                            </div>
                            <transition name="customerErrorTransition">
                              <p v-if="customerError" id="customer-error">
                                <span v-if="errorMessage">
                                  {{ errorMessage }}
                                </span>
                              </p>
                            </transition>
                        </div>
                        <p id="anti-spam">We don't spam or send marketing emails</p>
                    </div>
                    <div id="hero-right" class="column is-half">
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- seller section -->
    <section id="seller" class="hero">
        <div class="container">
            <div class="hero-body">
                <div class="columns">
                    <div id="seller-column" class="column">
                        <p id="seller-text">Apply to be a seller on AXS AMERICA, America's marketplace</p>
                        <button v-on:click="modal = !modal" class="button">APPLY NOW</button>
                    </div>
                    <div class="column is-three-fifths">
                        <img src="../assets/CART-FLAG-03.svg" alt="">
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div class="modal" v-bind:class="{ 'is-active': modal }">
      <div class="modal-background" v-on:click="modal = !modal"></div>
        <div class="modal-content">
          <div class="form-wrap">
              <p id="form-title">Sign up now to request access to our marketplace as a seller and be notified of our launch schedule and next steps.</p>
              <ul v-show="sellerError" id="seller-error">
                <li v-for="err in errorArr" :key="err">
                  {{ err }}
                </li>
              </ul>
              <h5 class="form-section-titles">Authorized Representative</h5>
              <div class="columns">
                <div class="column">
                  <label for="firstName">First Name</label>
                  <input class="input" type="text" name="firstName" v-model="firstName" placeholder="John">
                </div>
                <div class="column">
                  <label for="lastName">Last Name</label>
                  <input class="input" type="text" label="lastName" v-model="lastName" placeholder="Smith">
                </div>
              </div>
              <div class="columns">
                <div class="column">
                  <label for="sellerEmail">Email</label>
                  <input class="input" type="text" name="sellerEmail" v-model="sellerEmail" placeholder="jsmith@example.com">
                </div>
                <div class="column">
                  <label for="phone">Phone Number</label>
                  <input @input="formatPhone" class="input" type="tel" name="phone" v-model="phone" placeholder="(307) 555-5555">
                </div>
              </div>
              <h5 class="form-section-titles">Company Information</h5>
              <div class="columns">
                <div class="column">
                  <label for="businessName">Legal Business Name</label>
                  <input class="input" type="text" name="businessName" v-model="businessName" placeholder="Example LLC">
                </div>
                <div class="column">
                  <label for="dba">DBA</label>
                  <input class="input" name="dba" type="text" v-model="dba" placeholder="Ex.">
                </div>
              </div>
              <div class="columns">
                <div class="column">
                  <label for="address1">Address 1</label>
                  <input class="input" type="text" name="address1" v-model="address1" placeholder="123 Example Way">
                </div>
                <div class="column">
                  <label for="address2">Address 2</label>
                  <input class="input" type="text" name="address2" v-model="address2" placeholder="Suite 123">
                </div>
              </div>
              <div class="columns">
                <div class="column">
                  <label for="city">City</label>
                  <input class="input" type="text" name="city" v-model="city" placeholder="Cheyenne">
                </div>
                <div class="column">
                  <label for="state">State</label>
                  <input class="input" type="text" name="state" v-model="state" maxLength="2" placeholder="WY">
                </div>
              </div>
              <div class="columns">
                <div class="column">
                  <label for="zip">Zip Code</label>
                  <input class="input" type="text" v-model="zip" placeholder="82001">
                </div>
              </div>
              <button v-on:click="submitSeller" class="button is-fullwidth">NOTIFY ME</button>
            </div>
        <button class="modal-close is-large" aria-label="close" v-on:click="modal = !modal"></button>
      </div>
    </div>
  </article>
</template>

<script>
import axios from 'axios'

export default {
  name: 'LandingPage',
  data() {
    return {
      modal: false,
      customerEmail: '',
      sellerEmail: '',
      firstName: '',
      lastName: '',
      businessName: '',
      dba: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      zip: '',
      phone: '',
      customerError: false,
      sellerError: false,
      errorMessage: '',
      errorArr: [],
      success: false
    }
  },
  methods: {
    formatPhone() {
      var x = this.phone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.phone = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
    },
    validateEmail(email) {
      return /\S+@\S+\.\S+/.test(email);
    },
    validateString(string) {
      return string.trim().length;
    }, 
    validateZip(zip) {
      return /^\d{5}(-\d{4})?$/.test(zip);
    },
    async submitCustomer() {
      const url = 'https://34741.axsamerica.com/customer/';
      
      if(this.validateEmail(this.customerEmail)) {
        await axios.post(`${url}${this.customerEmail}`)
        .then(response => { 
          this.success = true; 
          this.customerError = false;
          this.errorMessage = "";
          console.log(response);
          this.$router.push({ name: "ThankYou"})
        })
        .catch(error => {
          this.customerError = true;
          this.success = false;
          this.errorMessage = "There was an issue submitting your email, please try again."
          console.log(error)
        });
      } else {
        this.customerError = true;
        this.errorMessage = 'Please use a valid email address';
      }
    },
    async submitSeller() {
      const url = 'https://34741.axsamerica.com/seller/';
      //const url = 'https://localhost:8000/seller/';

      this.errorArr = [];
      this.errorMessage = "";
      this.sellerError = false;

      if(!this.validateString(this.firstName)) {
        this.sellerError = true;
        this.errorArr.push(`First Name is required`);
      }

      if(!this.validateString(this.lastName)) {
        this.sellerError = true;
        this.errorArr.push(`Last Name is required`);
      }

      if(!this.validateString(this.sellerEmail)) {
        this.sellerError = true;
        this.errorArr.push(`Email is required`);
      } else if (!this.validateEmail(this.sellerEmail)) {
        this.sellerError = true;
        this.errorArr.push(`Your email is incorrect`);
      }

      if(!this.validateString(this.businessName)) {
        this.sellerError = true;
        this.errorArr.push(`A business name is required`);
      }

      if(!this.validateString(this.address1)) {
        this.sellerError = true;
        this.errorArr.push(`An address is required`);
      }

      if(!this.validateString(this.city)) {
        this.sellerError = true;
        this.errorArr.push(`A city is required`);
      }

      if(!this.validateString(this.state)) {
        this.sellerError = true;
        this.errorArr.push(`A state is required`);
      }
      
      if(!this.validateZip(this.zip)) {
        this.sellerError = true;
        this.errorArr.push(`A 5 digit zip is required`);
      }

      if(!this.validateString(this.phone)) {
        this.sellerError = true;
        this.errorArr.push(`A 10 digit phone number is required`);
      }

      console.log(`error message ${this.sellerError}`)
      console.log(`error array size ${this.errorArr.length}`)

      if (this.sellerError === false && this.errorArr.length < 1) {
        fetch(`${url}${this.sellerEmail}`, {
          method: 'POST',
          body: JSON.stringify({
            data: {
              firstName: `${this.firstName}`,
              lastName: `${this.lastName}`,
              businessName: `${this.businessName}`,
              dba: `${this.dba}`,
              address1: `${this.address1}`,
              address2: `${this.address2}`,
              city: `${this.city}`,
              state: `${this.state}`,
              zip: `${this.zip}`,
              phone: `${this.phone}`,
            }
          }),
          headers: {
            "Content-Type": "application/json"
          }
        }).then(response => { 
          this.success = true; 
          this.errorMessage = "";
          console.log(response);
          this.modal = false;
          this.$router.push({ name: "ThankYou"})
        })
        .catch(error => {
          this.success = false;
          this.customerError = true;
          this.errorMessage = "There was an error submitting your information, please try again";
          console.log(error)
        });
      }
    }
  }

}
</script>
