import { createRouter, createWebHistory } from 'vue-router'
import ThankYou from '../views/ThankYou.vue'
import LandingPage from '../views/LandingPage.vue'

const routes = [
    {
        path: '/',
        name: 'LandingPage',
        component: LandingPage
    },
    {
        path: '/thank-you',
        name: 'ThankYou',
        component: ThankYou
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL), 
    routes,
})

export default router